import Quiz from "./pages/quiz";

function App() {
  document.body.addEventListener('keydown', function(e) {
    if (e.keyCode === 9){
      const buttonWrapper = document.getElementsByClassName("button-wrapper");
      if (buttonWrapper && buttonWrapper[0]) {
        buttonWrapper[0].classList.add("tab-mode");
      }
    }
  });
  return (
    <div className="App">
      <Quiz />
    </div>
  );
}

export default App;
