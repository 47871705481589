import React, { useState } from "react";
import { QUESTIONS } from "../functions/questions";
import TextTransition, { presets } from "react-text-transition";
import { Explanation } from "../components/explanations";
import { defaultLogMessages } from "../functions/stopthat";
import { Link } from "../components/Link";
import logo_jb from "../img/logo_jb.png";
import logo_tira from "../img/logo_tira.png";
import logo_ailectures from "../img/logo_ai_lectures.png";

export const Quiz = () => {
  const [quizState, setQuizState] = useState(QUIZ_STATES.INITIAL);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [numberOfQuestions, setNumberOfQuestions] = useState(QUESTIONS.length);
  const [answers, setAnswers] = useState([]);
  const [showSponsorModal, setShowSponsorModal] = useState(false);

  const startOver = () => {
    setQuizState(QUIZ_STATES.INITIAL);
  };

  const startQuiz = () => {
    defaultLogMessages(false);

    setQuizState(QUIZ_STATES.QUIZ_START_ANIMATION2);
    setTimeout(() => {
      setQuizState(QUIZ_STATES.QUIZ_STARTED_ANIMATION);

      setTimeout(() => {
        setQuizState(QUIZ_STATES.QUIZ_STARTED);
      }, 500);
    }, 500);

    setCurrentQuestion(QUESTIONS[0]);
    setCurrentIndex(0);
    setAnswers([]);
  };

  const handleOptionSelection = (index) => {
    setAnswers([...answers, index]);
    const nextIndex = currentIndex + 1;
    if (nextIndex < numberOfQuestions) {
      setCurrentIndex(nextIndex);
      setCurrentQuestion(QUESTIONS[nextIndex]);
    } else {
      showResults();
    }
  };

  const showResults = () => {
    setQuizState(QUIZ_STATES.QUIZ_END_ANIMATION2);
    setTimeout(() => {
      defaultLogMessages(true);
      setQuizState(QUIZ_STATES.QUIZ_RESULTS_ANIMATION);

      setTimeout(() => {
        setQuizState(QUIZ_STATES.QUIZ_RESULTS);
      }, 500);
    }, 500);
  };

  const calculateResults = () => {
    let results = [];
    let numberOfCorrectAnswers = 0;
    for (let index = 0; index < numberOfQuestions; index++) {
      const currentQuestion = QUESTIONS[index];
      const wasUserRight = currentQuestion.correctAnswer === answers[index];
      results.push({
        question: currentQuestion.question,
        correctAnswer: currentQuestion.options[currentQuestion.correctAnswer],
        userAnswer: currentQuestion.options[answers[index]],
        wasUserRight: wasUserRight,
        index: index,
        explanation: currentQuestion.explanation,
        code: currentQuestion.code,
      });
      if (wasUserRight) {
        numberOfCorrectAnswers = numberOfCorrectAnswers + 1;
      }
    }

    return [results, numberOfCorrectAnswers];
  };

  const modalSponsor = showSponsorModal ? (
    <div className="modal modal-sponsor">
      <div className="modal-wrapper">
        <div className="modal-wrapper-inner">
          <h1>Become a sponsor</h1>
          <p>
            JS Is Weird receives thousands of unique visitors every month. It's
            an educational platform disguised as a quirky quiz that attracts
            developers from all over the world.
          </p>
          <p>
            The website frequently experiences sudden bursts of visitors from a
            particular country, likely as a result of the website being shared
            on a popular local platform or by an influencer in a given country.
            That said, the top five countries of visitors to JS Is Weird are
            South Korea, China, The United States of America, India, and Brazil.
          </p>
          <p>
            By becoming a corporate sponsor, you could have your company's logo
            placed on the bottom of both the front page and the last page. A
            good fit would be a company that works with solutions aimed at
            developers.
          </p>
          <p>
            Send me an{" "}
            <a
              href="mailto:thisisrealai@gmail.com"
              target="_blank"
              rel="noreferrer"
            >
              e-mail
            </a>{" "}
            to get in touch or learn more about me on my{" "}
            <Link text="personal website" url="https://jacobbergdahl.com/" />.
          </p>
          <div className="button-wrapper">
            <button onClick={() => setShowSponsorModal(!showSponsorModal)}>
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );

  if (quizState <= QUIZ_STATES.QUIZ_START_ANIMATION2) {
    const classes =
      "quiz-wrapper" +
      (quizState === QUIZ_STATES.QUIZ_START_ANIMATION1
        ? " no-overflow skew"
        : quizState === QUIZ_STATES.QUIZ_START_ANIMATION2
        ? " no-overflow slide-out"
        : "");
    return (
      <>
        <div className={classes}>
          <h1>JS Is Weird</h1>
          <p className="stylized-paragraph">
            JavaScript is a great programming language, but thanks to the fact
            that its initial release was built in only ten days back in 1995,
            coupled with the fact that JS is backward-compatible, it's also a
            bit weird. It doesn't always behave the way you might think. In this
            quiz, you'll be shown 25 quirky expressions and will have to guess
            the output. Even if you're a JS developer, most of this syntax is
            probably, and hopefully, not something you use in your daily life.
          </p>
          <div className="button-wrapper">
            <button onClick={() => startQuiz()}>Start</button>
          </div>
          <div className="s-outer-wrapper">
            <div className="s-wrapper">
              <span className="s-header">ALSO CHECK OUT</span>
              <div className="s-inner-wrapper">
                <a
                  href="https://jacobbergdahl.com/"
                  target="_blank"
                  rel="noreferrer"
                  title="About me: Jacob Bergdahl"
                >
                  <img src={logo_jb} alt="About me: Jacob Bergdahl" />
                </a>
                <a
                  href="https://thisisrealaibook.com/"
                  target="_blank"
                  rel="noreferrer"
                  title="My book: This Is Real AI"
                >
                  <img src={logo_tira} alt="My book: This Is Real AI" />
                </a>
                <a
                  href="https://aiforelasning.se/en/"
                  target="_blank"
                  rel="noreferrer"
                  title="My AI lectures"
                >
                  <img src={logo_ailectures} alt="My AI lectures" />
                </a>
              </div>
            </div>
          </div>
        </div>
        {modalSponsor}
      </>
    );
  } else if (quizState <= QUIZ_STATES.QUIZ_END_ANIMATION2) {
    const options = currentQuestion.options.map((option, i) => {
      return (
        <button
          onClick={() => handleOptionSelection(i)}
          key={i}
          className="no-select"
        >
          <TextTransition
            text={option}
            springConfig={presets.stiff}
            noOverflow={true}
          />
        </button>
      );
    });
    const classes =
      "quiz-wrapper quiz-active" +
      (quizState === QUIZ_STATES.QUIZ_STARTED_ANIMATION
        ? " no-overflow slide-in-init slide-in"
        : quizState === QUIZ_STATES.QUIZ_END_ANIMATION1
        ? " no-overflow skew"
        : quizState === QUIZ_STATES.QUIZ_END_ANIMATION2
        ? " no-overflow slide-out"
        : "");
    const progressClasses =
      "progress" +
      (quizState === QUIZ_STATES.QUIZ_STARTED_ANIMATION
        ? " progress-animation-one"
        : quizState === QUIZ_STATES.QUIZ_STARTED
        ? " progress-animation-one progress-animation-two"
        : "");
    return (
      <div className={classes}>
        <h1>
          <TextTransition
            text={currentQuestion.question}
            springConfig={presets.stiff}
            noOverflow={true}
          />
        </h1>
        <div className="button-wrapper">{options}</div>
        <div className={progressClasses}>
          <span className="margin-right">Question</span>
          <div className="margin-right">
            <TextTransition
              text={currentIndex + 1}
              springConfig={presets.stiff}
              noOverflow={true}
            />
          </div>
          <span className="margin-right">of</span>
          <span>{numberOfQuestions}</span>
        </div>
      </div>
    );
  } else {
    const [results, numberOfCorrectAnswers] = calculateResults();

    const listResults = results.map((result, index) => {
      return (
        <li
          key={result.index}
          className={`result-item ${
            result.wasUserRight ? "answered-correctly" : "answered-incorrectly"
          }`}
        >
          <span className="result-question">
            {result.index + 1}. {result.question}
          </span>
          <div className="result-item-inner-wrapper">
            <span className="result-correct-answer">
              Output: <span>{result.correctAnswer}</span>
            </span>
            <span className="result-user-answer">
              You answered: <span>{result.userAnswer}</span>
            </span>
            {result.wasUserRight ? (
              <span className="inline-answer answered-correctly">
                You got it right!
              </span>
            ) : index === 24 && result.userAnswer === "I give up" ? (
              <span className="inline-answer answered-truthfully">
                Fair enough, my friend.
              </span>
            ) : (
              <span className="inline-answer answered-incorrectly">
                You answered incorrectly.
              </span>
            )}
            <Explanation question={result.question} />
          </div>
        </li>
      );
    });

    const classes =
      "quiz-wrapper results-wrapper" +
      (quizState >= QUIZ_STATES.QUIZ_RESULTS_ANIMATION ? " show" : "");
    return (
      <>
        <div className={classes}>
          <h1>
            You got {numberOfCorrectAnswers} out of {numberOfQuestions} correct!
          </h1>
          <ul className="results-list">{listResults}</ul>
          <p className="stylized-paragraph stylized-paragraph-wide">
            I hope you thought this little quiz was fun, and, hopefully, you
            even learned something new. Thank you so much for all the insightful
            thoughts, funny comments, and amazing feedback that I've gotten on
            Reddit (especially r/javascript), Hacker News, and by e-mail!
          </p>
          <p className="stylized-paragraph stylized-paragraph-wide">
            This quiz was made by Jacob Bergdahl. Learn more about me on my{" "}
            <Link text="website" url="https://jacobbergdahl.com/" />, say hi on{" "}
            <Link
              text="LinkedIn"
              url="https://www.linkedin.com/in/jacobbergdahl/"
            />
            , and check out my{" "}
            <Link text="book" url="https://thisisrealaibook.com/" /> while
            you're at it.
          </p>
          <div className="button-wrapper">
            <button onClick={() => startOver()}>Start over</button>
          </div>
        </div>
      </>
    );
  }
};

const QUIZ_STATES = {
  INITIAL: 0,
  QUIZ_START_ANIMATION1: 1,
  QUIZ_START_ANIMATION2: 2,
  QUIZ_STARTED_ANIMATION: 3,
  QUIZ_STARTED: 4,
  QUIZ_END_ANIMATION1: 5,
  QUIZ_END_ANIMATION2: 6,
  QUIZ_RESULTS_ANIMATION: 7,
  QUIZ_RESULTS: 8,
};

export default Quiz;
