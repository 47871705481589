import { InlineCode } from "./InlineCode";
import { Link } from "./Link";

export const Explanation = ({ question }) => {
    let explanation = <span></span>;
    switch (question) {
        case "0.2 + 0.1 === 0.3":
            explanation = (
                <>
                    <p>This is a standard dilemma of comparing floating-point values. Instead of comparing two floating points directly, one should compare the floating points with some level of tolerance. <Link url="https://stackoverflow.com/questions/588004/is-floating-point-math-broken" text="This StackOverflow answer" /> explains the problem in greater detail.</p>
                    <code>
                        0.2 + 0.1; // -&gt; 0.30000000000000004;<br />
                        0.2 + 0.1 &gt; 0.3; // -&gt; true
                    </code>
                </>
            );
            break;
        case "0.3 + 0 === 0.3":
            explanation = (
                <>
                    <p>This question is just a red herring; there's nothing unusual going on here.</p>
                </>
            );
            break;
        case "[] + []":
            explanation = (
                <>
                    <p>This question is closely tied to question 3. Again, the extremely simplified answer is that JavaScript converts the arrays to strings. Scroll up to question 3 to find resources that explain this behavior.</p>
                    <code>
                        [].toString(); // -&gt; ""<br />
                        "" + ""; // -&gt; ""
                    </code>
                    <p>Also, like I mentioned in the explanation for question 2, these expressions are equal, due to trailing commas:</p>
                    <code>
                        [] + [] === [,] + [,]; // -&gt; true
                    </code>
                    <p>Even though these arrays are different, they are both converted to empty strings:</p>
                    <code>
                        [].length; // -&gt; 0<br />
                        [,].length; // -&gt; 1<br />
                        [].toString() === [,].toString(); // -&gt; true
                    </code>
                    <p>Of course, this is also true:</p>
                    <code>
                        Number([]) === Number([,]); // -&gt; true
                    </code>
                </>
            );
            break;
        case "1 + 2 + \"3\"":
            explanation = (
                <>
                    <p>JavaScript will execute these operations from left to right. String concatenation will take priority when the number three is added with the string three.</p>
                    <code>
                        1 + 2; // -&gt; 3<br />
                        3 + "3";  // -&gt; "33"
                    </code>
                    <p>Since the operations are executed from left to right, the expressions will give a signficantly different output if it was the first or second operation that had contained a string:</p>
                    <code>
                        "1" + 2 + 3;  // -&gt; "123"<br />
                        1 + "2" + 3;  // -&gt; "123"
                    </code>
                </>
            );
            break;
        case "NaN === NaN":
            explanation = (
                <>
                    <p>This is certainly a legacy issue. See <Link text="Stephen Canon's explanation" url="https://stackoverflow.com/questions/1565164/what-is-the-rationale-for-all-comparisons-returning-false-for-ieee754-nan-values#1573715" /> as to why NaN isn't triple equal to itself.</p>
                    <p>This was later remedied with the <InlineCode>isNaN</InlineCode> function, which works properly:</p>
                    <code>
                        isNaN(NaN); // -&gt; true
                    </code>
                    <p>This is also one of the very few instances where the <InlineCode>Object.is</InlineCode> function disagrees with triple equal:</p>
                    <code>
                        NaN === NaN; // -&gt; false<br />
                        Object.is(NaN, NaN); // -&gt; true
                    </code>
                    <p>Another such rare instance can be seen in question 24.</p>
                </>
            );
            break;
        case "typeof NaN":
            explanation = (
                <>
                    <p>The <Link url="https://262.ecma-international.org/5.1/#sec-4.3.23" text="ECMAScript Language Specification" /> explains NaN as a number value that is a IEEE 754 “Not-a-Number” value. It might seem strange, but this is a common computer science principle.</p>
                    <p>There are some odd issues surrounding NaN in JavaScript, however. For instance, this is one of the two only instances where the <InlineCode>Object.is</InlineCode> function disagrees with triple equals.</p>
                    <code>
                        NaN === NaN; // -&gt; false<br />
                        Object.is(NaN, NaN); // -&gt; true
                    </code>
                    <p>If you want to see if a value is NaN, then you should always use the <InlineCode>isNaN</InlineCode> function.</p>
                    <code>
                        isNaN(NaN); // -&gt; true
                    </code>
                    <p>Unrelated, but in case you are curious about the other case where the <InlineCode>Object.is</InlineCode> function disagrees with triple equals, it's 0 and -0.</p>
                    <code>
                        0 === -0; // -&gt; true<br />
                        Object.is(0, -0); // -&gt; false
                    </code>
                </>
            );
            break;
        case "+0 === -0":
            explanation = (
                <>
                    <p>Positive zero and negative zero are triple equal in JavaScript. However, the <InlineCode>Object.is</InlineCode> function disagrees. This is one of the very few situations where triple equal and <InlineCode>Object.is</InlineCode> will disagree with one another.</p>
                    <code>
                        +0 === -0; // -&gt; true<br />
                        Object.is(+0, -0); // -&gt; false
                    </code>
                    <p>You can find another such a situation in question 22.</p>
                </>
            );
            break;
        case "true + false":
            explanation = (
                <>
                    <p>According to the <Link url="https://262.ecma-international.org/5.1/#sec-11.6" text="ECMAScript Language Specification" />, the two boolean values are type coerced into their numeric counterparts.</p>
                    <code>
                        Number(true); // -&gt; 1<br />
                        Number(false); // -&gt; 0<br />
                        1 + 0; // -&gt; 1
                    </code>
                </>
            );
            break;
        case "[,,,].length":
            explanation = (
                <>
                    <p><InlineCode>[,,,]</InlineCode> outputs an array with three empty slots. The last comma is a <Link text="trailing comma" url="https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Trailing_commas" />.</p>
                    <p>If you don't think this is weird enough yet, then take a look at this:</p>
                    <code>
                        [,] + [,]; // -&gt; ""<br />
                        [] + [] === [,] + [,]; // -&gt; true<br />
                        [,,,] + [,,,]; // -&gt; ",,,,"<br />
                        ([,,,] + [,,,]).length === [,,,,].length; // -&gt; true
                    </code>
                    <p>To find resources that explain the addition operator with arrays, take a look at the explanation for question 3, directly below this.</p>
                </>
            );
            break;
        case "[1, 2, 3] + [4, 5, 6]":
            explanation = (
                <>
                    <p>The extremely simplified answer is that the arrays are converted to strings and are then concatenated. See <Link text="Denys Dovhan's explanation" url="https://github.com/denysdovhan/wtfjs#adding-arrays" /> for how this happens. To learn more about this behavior, visit <Link url="https://stackoverflow.com/questions/9032856/what-is-the-explanation-for-these-bizarre-javascript-behaviours-mentioned-in-the" text="this StackOverflow answer" /> for a mid-level explanation or <Link text="this blog post" url="https://2ality.com/2012/01/object-plus-object.html" /> for a more detailed one.</p>
                    <p>Adding a trailing comma doesn't change anything, by the way:</p>
                    <code>
                        [1, 2, 3,] + [4, 5, 6]; // -&gt; "1,2,34,5,6"
                    </code>
                    <p>But, I suppose, if you really want to convert your arrays to comma-separated strings and combine them, you could write something stupid like this:</p>
                    <code>
                        [1, 2, 3] + [, 4, 5, 6]; // -&gt; "1,2,3,4,5,6"
                    </code>
                    <p>Or, even dumber, this:</p>
                    <code>
                        [1, 2, 3, ""] + [4, 5, 6]; // -&gt; "1,2,3,4,5,6"
                    </code>
                    <p>Probably best not to use the addition operator together with arrays, though. If you do want to combine two arrays for real, this is a better approach:</p>
                    <code>
                        [...[1, 2, 3], ...[4, 5, 6]];
                    </code>
                </>
            );
            break;
        case "!!\"\"":
            explanation = (
                <>
                    <p>You can add two exclamation marks before any value to get its corresponding boolean primitive. The technical term for this is <Link text="double NOT" url="https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Logical_NOT#double_not_!!"/> (yes, really, Borat would love it). The conversion is based on the <Link text="truthyness" url="https://developer.mozilla.org/en-US/docs/Glossary/Truthy" /> or <Link text="falsyness" url="https://developer.mozilla.org/en-US/docs/Glossary/Falsy" /> of the value.</p>
                    <code>
                        !!""; // -&gt; false<br />
                        !!0; // -&gt; false<br />
                        !!"Pineapple"; // -&gt; true<br />
                        !!42; // -&gt; true
                    </code>
                    <p>This same conversion can be done by using the <InlineCode>Boolean</InlineCode> function.</p>
                    <code>
                        Boolean(""); // -&gt; false
                    </code>
                </>
            );
            break;
        case "+!![]":
            explanation = (
                <>
                    <p>Arrays are <Link text="truthy" url="https://developer.mozilla.org/en-US/docs/Glossary/Truthy" />, hence the double NOT operator will output true. The plus character then converts true to its numeric representation: 1.</p>
                    <code>
                        !![]; // -&gt; true<br />
                        +true; // -&gt; 1
                    </code>
                    <p>This expression might become clearer if written explicitly.</p>
                    <code>
                        Number(Boolean([])); // -&gt; 1
                    </code>
                </>
            );
            break;
        case "!!!true":
            explanation = (
                <>
                    <p>It's incredibly unusual to put three or more exclamation marks in a row, so you may not realize that it is something you can even do.</p>
                    <p>But why stop at only three when you could write amazingly unreadable code?</p>
                    <code>
                        !!!!!!!!!!!!true; // -&gt; true
                    </code>
                </>
            );
            break;
        case "true == \"true\"":
            explanation = (
                <>
                    <p>According to the rules of <Link text="abstract equality comparison" url="https://262.ecma-international.org/11.0/#sec-abstract-equality-comparison" />, both of these values are converted to numbers when compared.</p>
                    <code>
                        Number(true); // -&gt; 1<br />
                        Number("true"); // -&gt; NaN<br />
                        1 == NaN; // -&gt; false
                    </code>
                </>
            );
            break;
        case "010 - 03":
            explanation = (
                <>
                    <p>010 is treated as an octal number by JavaScript. Thus, its value is in base 8. <Link text="The MDN Web Docs provides a brief explanation of octal numbers" url="https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Numbers_and_dates#octal_numbers" />.</p>
                    <code>
                        010; // -&gt; 8<br />
                        03; // -&gt; 3<br />
                        8 - 3; // -&gt; 5
                    </code>
                    <p>You can go all out with octal numbers, if you'd like:</p>
                    <code>
                        01111111111111111; // -&gt; 40210710958665
                    </code>
                    <p>By the way, the number of leading zeroes doesn't matter:</p>
                    <code>
                        010 === 0000000010; // -&gt; true
                    </code>
                </>
            );
            break;
        case "false * 2":
            explanation = (
                <>
                    <p>The multiplication operator makes it clear to JS that false should be converted to a number.</p>
                    <code>
                        Number(false); // -&gt; 0<br />
                        0 * 2; // -&gt; 0
                    </code>
                </>
            );
            break;
        case "undefined + false":
            explanation = (
                <>
                    <p>While false can be converted to a number, undefined cannot.</p>
                    <code>
                        Number(undefined); // -&gt; NaN<br />
                        Number(false); // -&gt; 0<br />
                        NaN + 0; // -&gt; NaN
                    </code>
                    <p>However, undefined <i>is</i> represented by false:</p>
                    <code>
                        !!undefined === false; // -&gt; true
                    </code>
                    <p>Which means that we can add undefined and false like so:</p>
                    <code>
                        !!undefined + false; // -&gt; 0
                    </code>
                </>
            );
            break;
        case "null + 0":
            explanation = (
                <>
                    <p>Null converts to its numeric representation: 0.</p>
                    <code>
                        Number(null); // -&gt; 0<br />
                        0 + 0; // -&gt; 0
                    </code>
                    <p>This also means that while...</p>
                    <code>
                        null === false; // -&gt; false
                    </code>
                    <p>... this is true:</p>
                    <code>
                        +null === +false; // -&gt; true
                    </code>
                </>
            );
            break;
        case "0/0":
            explanation = (
                <>
                    <p>As there is no meaningful numerical answer to the equation 0/0, the output is simply <InlineCode>NaN</InlineCode>.</p>
                    <code>
                        isNaN(0/0); // -&gt; true
                    </code>
                </>
            );
            break;
        case "\"\" && -0":
            explanation = (
                <>
                    <p>Chances are that you've only ever used the <Link text="logical AND operator" url="https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Logical_AND" /> in if-statements, but when used by itself it actually returns the value of one of the operands. If the first expression is truthy, then it returns the second. Otherwise, it returns the first. An empty string is <Link text="falsy" url="https://developer.mozilla.org/en-US/docs/Glossary/Falsy" />, hence it returns the first operand.</p>
                    <code>
                        "" && -0; // -&gt; ""<br />
                        -0 && ""; // -&gt; -0<br />
                        5 && 3; // -&gt; 3<br />
                        0 && 3; // -&gt; 0<br />
                    </code>
                </>
            );
            break;
        case "1/0 > 10 ** 1000":
            explanation = (
                <>
                    <p>JavaScript treats both of these values as infinite, and infinity is equal to infinity. <Link text="Learn more about infinities" url="https://en.wikipedia.org/wiki/Floating-point_arithmetic#Infinities" />.</p>
                    <code>
                        1/0; // -&gt; Infinity<br />
                        10 ** 1000; // -&gt; Infinity<br />
                        Infinity &gt; Infinity; // -&gt; false
                    </code>
                    <p>The exponentiation operator <InlineCode>**</InlineCode> is <Link url="https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Exponentiation" text="basically" /> the same thing as the <InlineCode>Math.pow</InlineCode> function.</p>
                    <code>
                        10 ** 1000; // -&gt; Infinity<br />
                        Math.pow(10, 1000); // -&gt; Infinity
                    </code>
                </>
            );
            break;
        case "NaN++":
            explanation = (
                <>
                    <p>Attempting to increment NaN will simply output NaN.</p>
                    <code>
                        let _NaN = NaN;<br />
                        _NaN++;<br />
                        isNaN(_NaN); // -&gt; true<br />
                        _NaN--;<br />
                        isNaN(_NaN); // -&gt; true<br />
                        _NaN *= 10;<br />
                        isNaN(_NaN); // -&gt; true
                    </code>
                </>
            );
            break;
        case "true++":
            explanation = (
                <>
                    <p>Our first and only syntax error. I put SyntaxError as an option on a lot of the questions, hoping that some users would find some syntax so bizarre that it could not possibly be allowed. So, I felt that I had to add at least one expression that actually does result in a SyntaxError.</p>
                    <p>Some global variables and functions won't throw syntax errors, by the way:</p>
                    <code>
                        true++; // -&gt; SyntaxError<br />
                        1++; // -&gt; SyntaxError<br />
                        "x"++; // -&gt; SyntaxError<br />
                        null++; // -&gt; SyntaxError<br />
                        undefined++; // -&gt; NaN<br />
                        $++; // -&gt; NaN<br />
                        console.log++; // -&gt; NaN<br />
                        NaN++; // -&gt; NaN
                    </code>
                    <p>And, of course, just to be completely clear, this is valid syntax:</p>
                    <code>
                        let _true = true;<br />
                        _true++;<br />
                        _true; // -&gt; 2
                    </code>
                </>
            );
            break;
        case "\"\" - - \"\"":
            explanation = (
                <>
                    <p>These two empty strings are both converted to 0.</p>
                    <code>
                        Number(""); // -&gt; 0<br/>
                        0 - - 0; // -&gt; 0
                    </code>
                    <p>The expression might become a bit clearer if I write it like this:</p>
                    <code>
                        +"" - -"";<br />
                        +0 - -0;
                    </code>
                    <p>Please note that, while I put the space between the minus sign and the empty string simply to attempt to confuse you, the space between the minus signs themselves is important:</p>
                    <code>
                        - -""; // -&gt; 0<br />
                        --""; // -&gt; SyntaxError
                    </code>
                </>
            );
            break;
        case "\"\" - 1":
            explanation = (
                <>
                    <p>While the addition operator (+) is used both for numbers and strings, the subtraction operator (-) has no use for strings, so JavaScript interprets this as an operation between numbers. An empty string is type coerced into 0.</p>
                    <code>
                        Number(""); // -&gt; 0<br/>
                        0 - 1; // -&gt; -1;
                    </code>
                    <p>This would still be true even if the string had a space (or more) inside of it:</p>
                    <code>
                        " " - 1; // -&gt; -1;
                    </code>
                    <p>However, if we use the addition operator, then string concatenation takes priority:</p>
                    <code>
                        "" + 1; // -&gt; "1";
                    </code>
                </>
            );
            break;
        case "parseInt(0.0000005)":
            explanation = (
                <>
                    <p>Yeah, this is genuinely wild.</p>
                    <code>
                        parseInt(0.5); // -&gt; 0<br/>
                        parseInt(0.05); // -&gt; 0<br/>
                        parseInt(0.005); // -&gt; 0<br/>
                        parseInt(0.0005); // -&gt; 0<br/>
                        parseInt(0.00005); // -&gt; 0<br/>
                        parseInt(0.000005); // -&gt; 0<br/>
                        parseInt(0.0000005); // -&gt; 5
                    </code>
                    <p>The <InlineCode>parseInt</InlineCode> function converts its first argument into a string (if it isn't one already) and <i>then</i> to a number. When 0.0000005 is converted into a string, this happens:</p>
                    <code>
                        String(0.0000005); // -&gt; "5e-7"
                    </code>
                    <p>The <InlineCode>parseInt</InlineCode> function then simply takes the first character from that string, namely 5, and parses only that character into a number. Full credit to <Link text="Dmitri Pavlutin" url="https://dmitripavlutin.com/parseint-mystery-javascript/" /> for explaining this behavior. Please check out his website for a more detailed explanation (as well as an alternative function that you could use instead).</p>
                </>
            );
            break;
        case "(null - 0) + \"0\"":
            explanation = (
                <>
                    <p><InlineCode>null</InlineCode> is coerced into 0.</p>
                    <code>
                        Number(null); // -&gt; 0<br />
                        0 - 0; // -&gt; 0<br />
                        0 + "0"; // -&gt; "00"
                    </code>
                    <p>If the question had used only the subtraction operator, the result would have been different:</p>
                    <code>
                        (null - 0) - "0"; // -&gt; 0
                    </code>
                </>
            );
            break;
        case "true + (\"true\" - 0)":
            explanation = (
                <>
                    <p>You might suspect that JS is so bananas that it would convert the string literal "true" into the numeric representation of the boolean true. It's not quite that bananas, however. What actually happens is that it tries to convert the string to a number and fails.</p>
                    <code>
                        Number("true"); // -&gt; NaN
                    </code>
                </>
            );
            break;
        case "10,2":
            explanation = (
                <>
                    <p>The <Link text="comma operator" url="https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Comma_Operator" /> simply returns the value of the last operand.</p>
                    <code>
                        10, 2; // -&gt; 2<br />
                        1, 2, 3, 4; // -&gt; 4<br />
                        42, "pineapple", true; // -&gt; true
                    </code>
                </>
            );
            break;
        case "!5 + !5":
            explanation = (
                <>
                    <p>Putting a solemn exclamation mark, also known as the logical NOT operator, before a non-Boolean value will convert it to a boolean value opposite of what the <InlineCode>Boolean</InlineCode> function would convert it into.</p>
                    <p>5 is truthy:</p>
                    <code>
                        Boolean(5); // -&gt; true<br />
                        !!5; // -&gt; true<br />
                    </code>
                    <p>The opposite of true is, of course, false, which in turn is coerced into 0:</p>
                    <code>
                        !5; // -&gt; false<br />
                        +false; // -&gt; 0<br />
                        0 + 0; // -&gt; 0
                    </code>
                </>
            );
            break;
        case "+Infinity === -Infinity":
            explanation = (
                <>
                    <p>Infinity may be equal to infinity, but negative and positive infinities are not.</p>
                    <code>
                        Number.POSITIVE_INFINITY === Number.NEGATIVE_INFINITY; // -&gt; false
                    </code>
                </>
            );
            break;
        case "undefined === undefined":
            explanation = (
                <>
                    <p>This is a red herring. The output is what you would probably expect.</p>
                    <p>But would you have expected this?</p>
                    <code>
                        undefined++ === undefined++; // -&gt; false
                    </code>
                    <p>As I mentioned in the explanation for question 15, <InlineCode>undefined++</InlineCode> outputs <InlineCode>NaN</InlineCode>. Thus, this is false because <InlineCode>NaN !== NaN</InlineCode>, as we learned in question 22.</p>
                </>
            );
            break;
        case "!!\"JS is weird\"":
            explanation = (
                <>
                    <p>I feel like I should have accepted all four options as a correct answer for comedic effect, so sorry if your perfect score was shattered if you argued that this statement is only maybe true.</p>
                    <code>
                        Boolean("JS is weird"); // -&gt; true
                    </code>
                </>
            );
            break;
        case "+!!NaN * \"\" - - [,]":
            explanation = (
                <>
                    <p>The finale combines some of the bizarre syntax that I've covered in this quiz. I've explained all of this behavior already in the explanations above, except for the multiplication operator (*), which will coerce the empty string into its numeric equivalent: 0.</p>
                    <code>
                        +!!NaN; // -&gt; 0<br/>
                        +""; // -&gt; 0<br/>
                        -[,]; // -&gt; -0<br/>
                    </code>
                    <p>Add it all together:</p>
                    <code>
                        0 * 0 - -0; // -&gt; 0
                    </code>
                </>
            );
            break;
        case "copy":
            explanation = (
                <>
                </>
            );
            break;
    }

    return (
        <>
            {explanation}
        </>
    )
}